/*
 * 업무구분 : 계약관리
 * 화면 명 : MSPPM731M
 * 화면 설명: 우량체미변경계약
 * 접근권한: FC, 지점장
 * 작 성 일: 2023.02.02
 * 작 성 자: 서영화
 */
<template>
  <ur-page-container class="msp" title="우량체미변경계약" :show-title="true" type="subpage" action-type="search" @on-header-left-click="fn_ClickBackBtn" @action-search-click="fn_OpenMSPPM732P()" @on-scroll-bottom="fn_LoadingData()" :topButton="true" >
    <!-- 고정영역 -->
    <template #frame-header-fixed>
      <ur-box-container alignV="start"  direction="column" class="msp-pm-wrap">

        <!-- 검색결과 영역 start-->
        <ur-box-container  v-if="isSearched && !isCleared" alignV="start" direction="column" class="search-result-box bd-T-Ty1 bd-b-Ty1"> 
          <strong class="tit">검색결과</strong>
          <mo-tab-box  default-idx="1" primary class="ns-tabs-box-o nw-tab-sub ns-tabs-box2 no-tab">
            <template v-for="item in searchedItems">
              <mo-tab-label v-if="!$bizUtil.isEmpty(item.text)" @click="fn_OpenMSPPM732P" :idx="item.idx" :key="item.idx" >
                <span class="tit" v-if="!$bizUtil.isEmpty(item.title)">{{ item.title }}</span>
                <span class="txt">{{ item.text }}</span> 
              </mo-tab-label>
            </template>
          </mo-tab-box>
        </ur-box-container>
        <!-- 검색결과 영역 end-->

        <!-- 데이터개수 영역 start -->
        <ur-box-container alignV="start" componentid="ur-box-container_001" direction="column" class="ns-check-sum">
          <mo-list-item>
            <div  class="list-item__contents">
              <div class="list-item__contents__title">
                <span v-if="checkedCount <= 0" class="sum">총 <strong class="crTy-blue3">{{ totalCount }}</strong> 명</span>
                <span v-else class="sum"><strong class="crTy-blue3">{{ checkedCount }}</strong> 명 선택</span>
              </div>
            </div>
          </mo-list-item>
        </ur-box-container>
        <!-- 데이터개수 영역 end -->
      </ur-box-container>
    </template>

    <!-- 컨텐츠 영역 start -->
    <ur-box-container direction="column" alignV="start" class="msp-pm-wrap"> 
      <ur-box-container direction="column" alignV="start" class="ns-list-area msp-list-area pb150"> 
        <ur-box-container v-if="!isNoData" alignV="start" componentid="" direction="column" class="ns-check-list type-2">
          <mo-list :list-data="icsprtyUnChgContVO">
            <template #list-item="{ index, item }">
              <mo-list-item expandable prevent-expand class="type-itm-2" :class="{'on' : item.checked === true}" @click.native.stop="fn_OnClickListItem(index)">
                <mo-checkbox v-model="item.checked" @input="fn_ClickCheckPartial" :disabled="isDisabledCheck" :ref="`chk${index}`"/>
                <div class="list-item__contents">
                  <!-- 계약자명 -->
                  <div class="list-item__contents__title mb10">
                    <!-- 고객카드 없음 240129 추가 -->
                    <span class="name" style="display: flex;">
                      <span class="txtSkip maxW100" @click.stop="fn_OpenCustInfoBs(item)">
                        {{ item.contrNm }}
                      </span>
                      <span :class="!item.chnlCustId.trim() ? 'need-cs-card' : null" />
                    </span>
                    <!-- 고객카드 없음 240129 추가 -->
                  </div>
                  <div @click.stop="fn_MoveMSPPM931M(item.contNoEnc, item.chnlCustId)" class="list-item__contents__title fwn linkTy">
                    <!-- 상품명 -->
                    <span class="tit fs16rem txtSkip">{{ item.prdtNm }}</span>
                    <!-- 계약번호 -->
                    <span class="prd-num fs16rem pl3">({{ item.markUpContNo }})</span>
                  </div>
                  <div class="list-item__contents__info">
                    <span class="min50 fs14rem crTy-bk7 mr10">계약일자</span><span class="crTy-bk1">{{ item.contYmd }}</span>
                  </div>
                  <div class="list-item__contents__info">
                    <span class="min50 fs14rem crTy-bk7 mr10">대상자</span><span class="crTy-bk1 txtSkip maxW80">{{ item.prefrChgObjrNm }}</span>
                  </div>
                </div>
                <!-- 아코디언 컨텐츠 -->
                <template v-slot:expand>
                  <div class="list-item-detail pt7 pb7">
                    <div class="expand-inner-info-wrap">
                      <div class="contents-row">
                        <span class="fs14rem min50 mr10">진단일자</span><span class="crTy-bk1">{{ item.dignYmd }}</span><em>|</em>
                        <span class="fs14rem min50 mr10">계약상태</span><span class="crTy-bk1">{{ item.contStatCdNm }}</span>
                      </div>
                      <div class="contents-row">
                        <span class="fs14rem min50 mr10">보험료</span><span class="crTy-blue3 fwb">{{ item.prm.amount }}</span>
                      </div>
                    </div>
                  </div>
                </template>
              </mo-list-item>
            </template>
          </mo-list>   
        </ur-box-container>

        <!-- NoData 영역 start -->
        <ur-box-container v-else alignV="start" componentid="ur_box_container_005" direction="column" class="ns-check-list no-data">
          <mo-list-item>
            <div class="list-item__contents">
              <div class="list-item__contents__info">
                <span>데이터가 없습니다.</span>
              </div>
            </div>
          </mo-list-item>
        </ur-box-container>
        <!-- NoData 영역 end -->

      </ur-box-container>
    </ur-box-container>
    <!-- 컨텐츠 영역 end -->

    <!-- 계약 로딩 표시 -->
    <div class="msp loading--dim msp-in-page" v-show="isReading">
      <div class="preloader-bubbling">
        <span id="bubbling_1"></span>
        <span id="bubbling_2"></span>
        <span id="bubbling_3"></span>
      </div>
      <div class="loading-text-box">
        <span class="title">{{loadingMsg}}</span>
        <span class="txt" v-html="loadingSubMsg" />
      </div>
    </div>

  </ur-page-container>
</template>

<script>

import Msg       from '@/systems/webkit/msg/msg'
import MSPPM732P from '@/ui/pm/MSPPM732P'         // 우량체미변경계약 상세조건
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import Screen from '~systems/mixin/screen'

export default {
  name: 'MSPPM731M', 
  screenId: 'MSPPM731M', 
  mixins: [Screen],
  components: {    
    MSPPM732P,
  },//componets,

  props:{},//props

  data () {
    return {
      title: '우량체미변경계약',

      lv_inputParam: {},          // 서비스호출 파라미터

      /**
       * 사용자 정보 변수
       */
      userInfo: {},               // 조직 및 컨설턴트 정보
      isMngr: false,              // 지점장 여부 
      pOrgDataMSPPM731M: {},      // 최근 조회조건 데이터 

      /**
       * 화면 변수
       */
      isNoData: false,            
      isLoading: false,
      isReading: false,
      loadingMsg: '고객 정보 조회중',
      loadingSubMsg: '2~3분 정도 소요될 수 있습니다.',

      /**
       * 더보기 key
       */
      stndKeyList: [], 
      stndKeyVal: '',
      pageRowCnt: '20',

      /**
       * 체크박스 변수
       */
      isDisabledCheck: false,     // 체크박스 비활성화 여부 (타 컨설턴트 고객 호출 하면 체크박스 비활성화) 
      totalCount: 0,              // 전체 고객수 
      selectedItems: [],          // 선택된 고객 리스트 

      /**
       * 조회 변수
       */
      icsprtyUnChgContVO: [],     // 우량체미변경계약 조회 데이터
      searchedCustNm: '',

      searchedItems: {},        // 검색결과 데이터 

      /**
       * 제어 변수 
       */ 
      isSearched: false,        // 조회이력 저장 변수  
      isCleared: true,          // 조회조건 초기화 여부 

      /** 
       * 팝업 연동
       */
      popup732: {},             // MSPPM732P 우량체미변경계약 상세조건

      

    }
  },//data

  created () {

    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_ClickBackBtn)     // backKey Event 등록
    window.vue.getStore('progress').dispatch('SKEL_LIST_CHK')                       // post 호출 전 Progress 타입 설정

    this.userInfo = this.getStore('userInfo').getters.getUserInfo                   // 사용자 정보

    /**
     * 지점장 여부를 확인한다.
     * 해당 사용자는 본인이 아닌 FC번호로 조회가능하다.
     */
    if(this.$cmUtil.isSrchOrgCombo() === 'Y') this.isMngr = true

    this.fn_Submit()

  },//created

  mounted () {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },//mounted

  beforeDestroy () {
    
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_ClickBackBtn)      // backKey Event 해제
    window.vue.getStore('progress').dispatch('FULL')                                  // post 호출 전 Progress 타입 설정

  },//beforeDestroy

  watch: {},//watch

  computed: {

    /**
     * 선택된 데이터 개수를 구한다.
     */
    checkedCount(){
      return this.icsprtyUnChgContVO.filter(item => item.checked).length
    },
        
    /**
     * 선택된 데이터를 추출한다. 
     */
    checkedList(){
      return this.icsprtyUnChgContVO.filter(item => item.checked)
    },

  },//computed

  methods: {

    /**
     * @description 초기 조회조건으로 초기화 한다.
     */
    fn_ClearCond(){
      
      this.pOrgDataMSPPM731M = {}   
      this.searchedCustNm    = ''   
      this.isSearched        = false   
      this.searchedItems     = {}        

    },
    
    /**
     * @description 체크된 값이 있는지 확인 후, 뒤로가기 실행 함수를 호출한다.
     */
    fn_ClickBackBtn(){
      
      if (this.checkedCount > 0) {
        this.fn_confirm()
        return 
      } 
      
      this.fn_BackToPrevious()

    },
    
    /**
     * @description - (현재조건값 === 초기조건값) 뒤로가기를 한다.
     *              - (현재조건값 !== 초기조건값) 조건 초기화 후 조회한다.
     */
    fn_BackToPrevious(){

      if(this.isSearched && !this.isCleared){  
        this.fn_ClearCond()
        this.fn_Submit()
      }else{
        // 메뉴로 진입했으면 홈화면으로 이동한다.
        if (this.$route.query?._isMenuItem) {
          this.$router.push({name: 'MSPBC002M'})
          return
        }
        this.$router.go(-1)
      }

    },
    
    /**
     * @description 조회에 필요한 파라미터 설정 및 초기화 후, 서비스를 호출한다.
     * @notice      상세조회 팝업에서 조직 항목 변경(컨설턴트) 후, 정상적인 조회 가능하다.
     *              조직 항목 변경(사업부, 지점, 지역단) 후, 정상적인 조회가 안된다면 VO부터 수정해야한다.
     *              VO에 clmDofOrgId, clmFofOrgId, clmHofOrgNo 필드 존재하지 않는다.
     */
    fn_Submit(){
            
      // 초기화  
      this.totalCount  = 0  
      this.stndKeyVal  = ''               
      this.stndKeyList = [] 
      this.isNoData    = false
      this.icsprtyUnChgContVO.splice(0)
      
      // 체크된 고객 초기화
      let selectItems_init = []
      this.$cmUtil.setSelectedCustList(this, selectItems_init)
      
      // 조직 및 컨설턴트 데이터 세팅 
      this.lv_inputParam.cnsltNo      = this.userInfo.userId        // 컨설턴트번호

      if(this.pOrgDataMSPPM731M.cnslt !== undefined && this.isMngr === true) {
        // user와 선택한 컨설턴트가 다를 경우 체크박스 비활성화
        if(this.userInfo.userId !== this.pOrgDataMSPPM731M.cnslt.key){
          this.isDisabledCheck = true
        } else {
          this.isDisabledCheck = false
        }
        // 선택된 조직 및 컨설턴트 데이터 세팅 
        this.lv_inputParam.cnsltNo = this.pOrgDataMSPPM731M.cnslt.key // 선택한 컨설턴트 번호

      }else{
        this.isDisabledCheck = false
      }

      this.lv_inputParam.stndKeyList = this.stndKeyList
      this.lv_inputParam.pageRowCnt  = this.pageRowCnt

      window.vue.getStore('progress').dispatch('SKEL_LIST_CHK') // post 호출 전 Progress 타입 설정.

      // 서비스 호출
      this.fn_GetService()

    },
   
   /**
     * @description 우량체미변경계약 목록 조회 서비스 호출한다.
     * @callback    fn_HandleCallback
     */
    fn_GetService() {

      if (this.stndKeyVal === 'end') return

      const trnstId = 'txTSSPM40S5'
      const auth = 'S'

      let pParams = this.lv_inputParam
      
      this.isLoading = true

      return this.post(this, pParams, trnstId, auth)
        .then(this.fn_HandleCallback)      
        .catch(function (error) { window.vue.error(error) })

    },
    
   /**
    * @description 콜백 데이터를 처리한다.
    * @param       {Object} response 콜백 데이터
    */
    fn_HandleCallback(response){

      let confirm = this.getStore('confirm')

      if(response.body !== null && response.msgComm !== null){

        let responseList = response.body.pMSprtyUnChgContVO // 우량체미변경계약 조회( 기존: icsprtyUnChgContVO )

        if(responseList !== null){

          // 우량체미변경계약 조회 데이터 가공 
          responseList.forEach((item, index) => {

            // 고객명이 존재할 경우
            if(!this.$bizUtil.isEmpty(this.searchedCustNm.trim())){
              let target = new String(this.searchedCustNm.trim())
              let isEng  = /[A-Za-z]/
              // 영어여부 및 uppercase
              if(isEng.test(target)) target = target.toUpperCase()
              // 고객명을 포함하지 않으면 return
              if(!responseList[index].contrNm.includes(target)) return;

            } 

            item.markUpContNo = item.contNo.substr(item.contNo.length - 5, 5)  
            item.contNoNum    = item.contNo.substr(item.contNo.length - 4, 4)
            item.contYmd      = this.$commonUtil.dateDashFormat(item.contYmd)
            item.dignYmd      = this.$commonUtil.dateDashFormat(item.dignYmd)
            item.prm.amount   = this.$commonUtil.numberWithCommas(item.prm.amount)
            
            this.$set(responseList[index], 'checked', false)
            this.$set(responseList[index], 'idx', index)
            //this.$set(responseList[index], 'chnlCustId', '')
            this.$set(responseList[index], 'custNm', item.contrNm)

            this.icsprtyUnChgContVO.push(responseList[index])

          })

          this.stndKeyList = []
          this.stndKeyList.push(response.trnstComm.stndKeyList[0])
          if (this.stndKeyList === null || this.stndKeyList[0] === null || this.stndKeyList[0].stndKeyVal === null || this.stndKeyList[0].stndKeyVal.trim() === '') {
            // 다음페이지 데이터 존재안함(마지막 페이지)
            this.stndKeyVal = 'end'
          } else {
            // 다음페이지 데이터 존재함
            this.stndKeyVal = 'more'
          }

          // 조회데이터 총 개수 
          this.fn_OrderByDataDesc()

          // 조회 총 카운트
          this.totalCount = this.icsprtyUnChgContVO.length

        }

      }else{
        confirm.dispatch('ADD', response.msgComm.msgDesc)
      }

      this.isLoading = false 

      // 데이터 유무 
      if (this.icsprtyUnChgContVO.length === 0) {
        this.isNoData = true    
      } else {
        this.isNoData = false  
      }

    },

    /**
     * @description 데이터를 내림차순으로 정렬한다.
     *              우선순위 : 진단일자(최신순) > 계약자명 > 계약번호
     */
    fn_OrderByDataDesc(){

      // 진단일자(최신순)
      this.icsprtyUnChgContVO.sort(function(a, b) {
        return new Date(b.dignYmd).getTime() - new Date(a.dignYmd).getTime()
      })

      // 계약자명
      this.icsprtyUnChgContVO.sort(function(a, b) {
        if(a.dignYmd === b.dignYmd){
          return a.contrNm > b.contrNm ? 1 : -1
        }
      })

      // 계약번호 
      this.icsprtyUnChgContVO.sort(function(a, b) {
        if(a.contrNm === b.contrNm){
          return a.contNoNum > b.contNoNum ? 1 : -1
        }
      })

    },
    
    /**
     * @description 조회목록을 부분선택 한다.
     */
    fn_ClickCheckPartial(){ 

      this.checkedList.forEach(e => {
        e.contNo    = e.contNoEnc
        e.contNoEnc = e.contNoEnc
        e.custNm    = e.contrNm
      })

      this.checkedList.busnCd = 'PM'

      // 공통 시트를 가리고 사용자 시트를 보여준다.
      this.$cmUtil.setSelectedCustList(this, this.checkedList)

    },

    /**
     * @description 체크박스 이외의 영역 클릭했을 때, 체크 활성화 및 비활성화 한다.
     * @param       {Number} index 리스트 데이터의 index
     */
    fn_OnClickListItem(index) {
      
      if(this.isDisabledCheck) return 
      
      this.$refs[`chk${index}`].onClick()
    },

    /**
     * @description 데이터를 로딩한다.
     */
    fn_LoadingData(){

      if (!this.isLoading) {
        
        window.vue.getStore('progress').dispatch('PART') // post 호출 전 Progress 타입 설정.

        this.fn_GetService()

      }

    },
    

    /**
     * @description 우량체미변경계약 상세조건 팝업을 제어한다.
     */
    fn_OpenMSPPM732P(){

      this.popup732 = this.$bottomModal.open(MSPPM732P,{
        properties: {
          pPage: this.$options.screenId,
          pIsMngr: this.isMngr,                     // 지점장 여부 
          pOrgData: this.pOrgDataMSPPM731M,         // 최근 조직 및 컨설턴트 
          pSelectedItems: this.searchedItems,       // 조회조건 
          pSearchedCustNm: this.searchedCustNm,     // 고객명 
        },

        listeners: {
          searchPopup: (pData) => {
            // 모달 닫기
            this.$bottomModal.close(this.popup732)

            this.searchedCustNm    = pData.rtnData.searchedCustNm  
            this.pOrgDataMSPPM731M = pData.rtnData.pOrgData
            this.searchedItems     = pData.rtnData.selectedItems
            this.isCleared         = pData.rtnData.isCleared 
            this.isSearched        = true

            // 우량체미변경계약 조회
            this.fn_Submit()

          },
        }
      })
    },
    
    /**
     * @description 계약자명을 클릭할 때 nameUI를 호출한다.
     * @param       {Object}  item 선택된 우량체미변경계약 데이터 
     */
    async fn_OpenCustInfoBs(item){

      let prcContNo  = '',
          cnsltNo    = '',  
          nmUiObject = {}

      // 계약번호 복호화
      if(!this.$bizUtil.isEmpty(item.contNoEnc)) {
        prcContNo  = await this.$commonUtil.getDecryptCall(this, [item.contNoEnc]) 
        prcContNo  = prcContNo[0]
      }

      if(this.pOrgDataMSPPM731M.cnslt === undefined || this.pOrgDataMSPPM731M.cnslt === null || this.pOrgDataMSPPM731M.cnslt === ''){
        cnsltNo = this.userInfo.userId
      }else{
        cnsltNo = this.pOrgDataMSPPM731M.cnslt.key
      }

      nmUiObject = {
        chnlCustId: item.chnlCustId,
        custNm: item.custNm,
        cnsltNo: cnsltNo,
        contNo : prcContNo,
        parentId: this.$options.screenId
      }

      this.$cmUtil.fn_OpenCustNmUiCard(this, nmUiObject, function (response){
        return
      })

    },

    /**
     * @description 보험계약기본사항(MSPPM) 화면으로 이동한다.
     *              @type viewId     수신화면, callerId   송신화면, chnlCustId  채널아이디
     *                    cnsltNo    (필수값) 컨설턴트번호, contNo     (필수값) 계약번호
     * @param       {String} contNo     계약번호
     * @param       {String} chnlCustId 채널아이디 
     */
    async fn_MoveMSPPM931M (contNo, chnlCustId) {

      let prcContNo   = '',
          cnsltNo     = '',
          contInfoObj = {}

      // 계약번호 복호화
      if(!this.$bizUtil.isEmpty(contNo)) {
        prcContNo = await this.$commonUtil.getDecryptCall(this, [contNo]) 
        prcContNo = prcContNo[0]
      }

      if(this.pOrgDataMSPPM731M.cnslt === undefined || this.pOrgDataMSPPM731M.cnslt === null || this.pOrgDataMSPPM731M.cnslt === ''){
        cnsltNo = this.userInfo.userId
      }else{
        cnsltNo = this.pOrgDataMSPPM731M.cnslt.key
      }

      contInfoObj = {
        viewId: null,                         
        callerId: this.$options.screenId,     
        chnlCustId: chnlCustId,               
        cnsltNo: cnsltNo,               
        contNo: prcContNo,                       
      }

      this.$router.push({name: 'MSPPM931M', params: contInfoObj})

    },

    /**
     * @description 로딩 메시지를 설정한다.
     * @param       pShowFlag Progress 화면 설정 Flag
     */
    fn_SetProgressMsgPop(pShowFlag) {
      this.isReading = pShowFlag
    },

    /**
     * @description confirm popup
     * @param {String} title 제목
     * @param {String} message confirm 내용
     */
    fn_confirm(title, message) {
      let lv_Vm = this
      let backMsg = '선택을 그만두고<br>이전 화면으로 이동하시겠습니까?'
      let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            type: 'B',
            content: backMsg,
            title_pos_btn: "예",
            title_neg_btn: "아니오"
          },
          listeners: {
            onPopupConfirm: () => {
              lv_Vm.$bottomModal.close(lv_AlertPop)
              this.fn_BackToPrevious()
            },
            onPopupClose: () => {
              lv_Vm.$bottomModal.close(lv_AlertPop)
            }
          }
        })
    },

 },// methods

}//export default


</script>